<template>
  <div class="pay_cashier" :style="{'minHeight':cHeight}">
    <div class="header"><img src="../assets/icon_back.png" alt="" @click="returnPage"><span>收银台</span></div>
    <div class="main">
      <div class="product">
        <div class="title">
          <div class="left">{{info.ProductName}}</div>
          <div class="price"><span>￥</span>{{info.OrderAmount}}</div>
        </div>
        <div class="detail">
          <img :src="info.icon" alt="">
          <div>{{info.desc}}</div>
        </div>
      </div>
      <div class="paytype">
        <div class="title">支付方式</div>
        <div class="list">
          <div class="item" v-if="!iswx&&info.productType==0">
            <div class="left">
              <img src="../assets/icon_alipay.png" alt="">
              <span>支付宝</span>
            </div>
            <img :src="paytype==0?require('../assets/icon_sel.png'):require('../assets/icon_unsel.png')" class="right" alt="" @click="chose(0)">
          </div>
          <div class="item" v-if="info.productType==0">
            <div class="left">
              <img src="../assets/icon_wechat.png" alt="">
              <span>微信支付</span>
            </div>
            <img :src="paytype==1?require('../assets/icon_sel.png'):require('../assets/icon_unsel.png')" class="right" alt="" @click="chose(1)">
          </div>
          <div class="item">
            <div class="left">
              <img src="../assets/icon_card.png" alt="">
              <span>对公打款</span>
            </div>
            <img :src="paytype==2?require('../assets/icon_sel.png'):require('../assets/icon_unsel.png')" class="right" alt="" @click="chose(2)">
          </div>
        </div>
        <div class="btn active" @click="pay">立即支付</div>
      </div>
    </div>
    <div class="foot">由无锡中铠信息咨询服务有限公司提供技术支持</div>
    <van-dialog v-model="show" confirm-button-text="已完成支付" confirm-button-color="#1387FF" class="confirmDialog" cancelButtonText="未完成支付" cancelButtonColor="#999"
      show-cancel-button @confirm="confirmFun" @cancel="confirmFun">
      <div class="title">支付提示</div>
      <div class="content">请确认是否已完成支付？</div>
    </van-dialog>
    
  </div>
</template>

<script>
import {
    GetPayProductInfo,LvdouCollegeCommonPay,returnurlPay,returnurlwxPay,paysystem,notifyurl,WxJsApiParamPay
  } from '@/api'
  export default {
    data() {
      return {
        cHeight: null,
        paytype:0,
        show:false,
        info:{},
        iswx:false
      }
    },
    mounted() {
      this.cHeight = (document.documentElement.clientHeight || document.body.clientHeight) + 'px'
      document.title ='支付申请'
      this.iswx = this.isWeiXin()
      if(this.iswx){
        this.paytype=1
      }else{
        this.paytype=0
      }
     GetPayProductInfo({
        "orderCustomerId": localStorage.getItem('orderCustomerId')
      }).then(res=>{
        if(res.state==0){
          var info=res.data
          if(info.productType==0){
            info.icon=require('../assets/img_lawyer.png')
            info.desc='支付成功后，律师将在24小时内为您提供电话咨询服务。'
          }else{
            info.icon=require('../assets/img_package.png')
            info.desc='支付成功后，法务助理将在1个工作日内为您开通会员套餐。'
            this.paytype=2
          }
          console.log(this.paytype)
          this.info=info
        }else{
          // this.$toast(res.data)
        }
      })
       if ((this.$route.query.payreturn && this.$route.query.payreturn == 1) || this.$route.query.is_success) {
        this.show = true
      }
    },
    methods: {
      chose(val){
        this.paytype=val
      },
      isWeiXin() {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') {
          return true;
        } else {
          return false;
        }
      },
      pay(){
        GetPayProductInfo({
          "orderCustomerId": localStorage.getItem('orderCustomerId')
        }).then(res=>{
          if(res.state==0){
            var info=res.data
            if(info.OrderState=='已取消'){
              this.$toast('支付已超时，订单已取消')
            }else if(info.PaySate=='已支付'){
              this.$router.push('pay_result?type=success')
            }else{
              this.goPay()
            }
          }else{
            this.$toast(res.data)
          }
        })
      },
      goPay(){
        if(this.paytype==2){
           this.$router.push('payment?id='+localStorage.getItem('orderCustomerId'))
        }else{
          var bankName='微信'
          if(this.paytype==0){
          bankName='支付宝'
          }
          var pc = 'h5'
          if (this.isWeiXin()) {
            pc = 'wxh5'
          }
          LvdouCollegeCommonPay({
            "orderCustomerId": localStorage.getItem('orderCustomerId'),
            "money": this.info.OrderAmount,
            "paytype": "09",
            "bankName": bankName,
            "pc": pc,
            "ip": "",
            "url": "https://consultmob.ilvdo.net"
          }).then(res=>{
            if(res.state==0){

              if(this.paytype==1){
                if (this.isWeiXin()) {
                  if (localStorage.getItem('wxOpenid')) {
                    this.callpay(this.info.ProductName, res.data.needpaymoney, res.data.payCustomerId)
                  } else {
                    this.$toast('您还没有微信授权，暂时无法进行微信支付')
                  }
                } else {
                  var url = JSON.parse(res.data.bankname).mweb_url
                  var ret = returnurlwxPay + '&payCustomerId=' + res.data.payCustomerId
                  location.href = url + '&redirect_url=' + encodeURIComponent(ret)
                }
              }else{
                this.zfbPay(res.data.payCustomerId)
              }
            }else{
              this.$toast(res.data)
            }
          })
        }
      },
       callpay(subjectname, orderneedpay, payid) {

        console.log({
          subjectname: subjectname,
          orderneedpay: orderneedpay,
          payid: payid
        })
        // debugger
        if (!typeof WeixinJSBridge == "undefined") {

          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.jsApiCall, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.jsApiCall);
            document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall);
          }
        } else {

          if (subjectname == null || orderneedpay == null || payid == null) {
            this.$toast('支付失败');
            return false;
          }
          var postData = {
            "subjectName": subjectname,
            "orderneedPpay": orderneedpay,
            "productID": '',
            "payID": payid,
            "openID": localStorage.getItem('wxOpenid')
          }
          WxJsApiParamPay(postData).then(res => {

            if (res.state == 0) {

              this.jsApiCall(res.data.result);
            }
          })

        }
      },
      //调用微信JS api 支付
      jsApiCall(wxJsApiParam) {
        var that = this;
        var json = JSON.parse(wxJsApiParam)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest',
          json, //josn串
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") { //支付成功后点击完成页面跳转
              window.location.href = returnurlPay;
            } else {
              that.show = true
            }
          });
      },
       zfbPay(payCustomerId) {
        console.log(returnurlwxPay)
        this.zfbUrl = paysystem + '?payid=' + payCustomerId + "&totalpay=" + this.info.OrderAmount + "&notifyurl=" + notifyurl +
          "&returnurl=" + returnurlwxPay + "&subjectname=" + this.info.ProductName + "&app_pay=";
        location.href = this.zfbUrl
      },
      confirmFun(){
        GetPayProductInfo({
          "orderCustomerId": localStorage.getItem('orderCustomerId')
        }).then(res=>{
          if(res.state==0){
            var info=res.data
            if(info.PaySate=='已支付'){
              this.$router.push('pay_result?type=success')
            }else{
              this.$router.push('pay_result?type=fail')
            }
          }else{
            this.$toast(res.data)
          }
        })
      },
      returnPage(){
        this.$router.replace('pay')
      }
    }
  }

</script>
<style scoped lang='scss'>
  .pay_cashier{
    background: #F4F4F4 url('../assets/bg2.png') no-repeat;
    background-size: 100% 600px;
    .header{
        text-align: center;
        padding-top: 20px;
        font-size: 30px;color: #FFFFFF;
        position: relative;
        margin-bottom: 42px;
        img{
          width: 48px;
          height: 48px;
          position: absolute;
          top: 12px;
          left: 22px;
        }
      }
    .main{
      padding: 0 32px 30px;
      .product{
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 30px;
        .title{
          padding: 34px 28px 27px;
          border-bottom: 1px solid #EEEEEE;
          font-size: 32px;color: #333333;
          position: relative;
          .price{
              font-weight: bold;
              color: #E10D0D;
              font-size: 36px;
              position: absolute;
              right: 32px;
              top: 36px;
              span{
              font-size: 28px;
              }
          }
        }
        .detail{
          padding: 44px 30px;
          width: 100%;
          box-sizing: border-box;
          display: flex;align-items: center;
          img{
            width: 120px;
            height: 120px;
            margin-right: 30px;
          }
          div{
            flex: 1;
            font-size: 26px;color: #999999;
          }
        }
      }
      .paytype{
        padding: 36px 20px 60px;
        background: #fff;
        border-radius: 16px;
        width: 100%;
        box-sizing: border-box;
        .title{
          font-size: 28px;
          color: #333333;
          margin-bottom: 36px;
        }
        .list{
          padding-bottom: 45px;
          border-bottom: 1px solid #EEEEEE;
          .item{
            position: relative;
            margin-bottom: 30px;
            .left{
              img{
                width: 40px;
                height: 40px;
                vertical-align: middle;
                margin-right: 7px;
              }
              span{
                font-size: 26px;
                color: #666666;
                vertical-align: middle;
              }
            }
            .right{
              width: 48px;
              height: 48px;
              position: absolute;
              right: 0;
              top: -1px;
            }
          }
          .item:last-child{
            margin-bottom: 0;
          }
        }
        .btn{
          height: 80px;
          background: #B9D2EC;
          border-radius: 40px;
          margin-top: 100px;
          font-size: 34px;
          color: #FFFFFF;
          text-align: center;
          line-height: 80px;
        }
        .active{
          background: #1387FF;
        }
      }
    }
    .foot{
      width: 100%;
      text-align: center;
      font-size: 22px;color: #999999;
      position: absolute;
      bottom: 36px;
      left: 0;
    }
  }

</style>
<style lang='scss'>
.pay_cashier{
  .confirmDialog{
    .van-dialog__content{
      padding: 65px 0 ;
      text-align: center;font-size: 30px;color: #333333;
      .title{
        font-size: 36px;color: #333333;margin-bottom: 50px;
      }
      .content{
        font-size: 32px;color: #999999;
      }
    }
  }
}
</style>